import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

export function getBaseUrl() {
  //return "https://localhost:44336"; //local
  //return "https://uatapi.connect365.live"; //UAT
  return "https://api.connect365.live"; //Prod
}

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
